var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看配置",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.beforeClose,
            width: "500",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: { "label-position": "right" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商户名称:", prop: "operationId" } },
                [_vm._v(" " + _vm._s(_vm.detailItemList.operationName) + " ")]
              ),
              _c(
                "el-form-item",
                { staticClass: "freeType", attrs: { label: "免费类型:" } },
                _vm._l(_vm.freeTypeList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { display: "inline-block" } },
                    [_vm._v(" " + _vm._s(item.freeRuleType) + "   ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("el-button", { on: { click: _vm.beforeClose } }, [
                _vm._v("返回"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }